import type { ComponentPropsWithoutRef, ReactNode } from "react";
import React, { useMemo } from "react";

import type { TypographySizes } from "@hexocean/braintrust-ui-components";
import {
  Box,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useGetEmployerPublicProfileQuery } from "@js/apps/employer/api";
import { EmployerLogoNameAndRating } from "@js/apps/employer/components";
import { OfferDetailsUpdateHistoryTable } from "@js/apps/jobs/apps/offers/components/details/update-history-table";
import { showOfferExpiresSection } from "@js/apps/jobs/apps/offers/utils";
import { getJobTypeTagMeta } from "@js/apps/jobs/components";
import { Date } from "@js/components/date";
import type { EmployerOffer, FreelancerOffer, Job } from "@js/types/jobs";
import {
  assertUnreachable,
  dateDifferenceFromNow,
  formatCurrency,
  getEnumLabel,
  pluralize,
} from "@js/utils";
import type { IsoDate } from "@js/utils/date/types";
import { DateFormats } from "@js/utils/date/types";

import { JOB_PAYMENT_TYPE_LABELS } from "../../constants";

import { OfferDetailsHeader } from "./header";

import styles from "./style.module.scss";

export type TalentOrEmployerOffer = EmployerOffer | FreelancerOffer;

type OfferDetailsProps = {
  job: Job;
  offer: TalentOrEmployerOffer;
  showUpdatedLabel?: boolean;
};

type DetailMetaContextType = {
  showUpdatedLabel: boolean;
  detailTypographySize?: TypographySizes;
};
const DetailComponentMetaContext = React.createContext<DetailMetaContextType>({
  showUpdatedLabel: false,
  detailTypographySize: "large",
});

const useDetailMetaContext = () => {
  const context = React.useContext(DetailComponentMetaContext);

  if (!context) {
    throw new Error(
      "useDetailMetaContext must be in scope of UpdatedContext provider",
    );
  }

  return context;
};

export const OfferDetails = ({
  offer,
  job,
  showUpdatedLabel = false,
}: OfferDetailsProps) => {
  let component: JSX.Element | null = null;
  const contextValue = useMemo(
    () => ({ showUpdatedLabel }),
    [showUpdatedLabel],
  );

  switch (job.job_type) {
    case ENUMS.JobType.DIRECT_HIRE:
    case ENUMS.JobType.FREELANCE:
    case ENUMS.JobType.GRANT: {
      component = (
        <RoundedBox>
          <OfferDetailsHeader offer={offer} />
          <OfferDetailsFacade offer={offer} job={job} />
          <OfferDetailsUpdateHistoryTable offer={offer} />
        </RoundedBox>
      );
      break;
    }
    default: {
      assertUnreachable(job.job_type);
      component = null;
    }
  }

  return (
    <DetailComponentMetaContext.Provider value={contextValue}>
      {component}
    </DetailComponentMetaContext.Provider>
  );
};

export const OfferDetailsForBYOTSignUp = ({
  offer,
}: {
  offer: FreelancerOffer;
}) => {
  const { data: employerData } = useGetEmployerPublicProfileQuery(
    offer.job.employer.id,
  );

  const contextValue: DetailMetaContextType = useMemo(
    () => ({ showUpdatedLabel: false, detailTypographySize: "medium" }),
    [],
  );

  if (!employerData) {
    return null;
  }

  return (
    <DetailComponentMetaContext.Provider value={contextValue}>
      <RoundedBox border="1px solid var(--soft-grey)">
        <OfferDetailsHeader offer={offer} smallerTypography />
        <Box mt={3}>
          <EmployerLogoNameAndRating
            employer={employerData}
            noUrl
            nameStyle={null}
            logoSize="medium"
            ratingColor="violet"
            ratingIconSize="small"
          />
        </Box>
        <DetailsLayoutContainer mt={3}>
          <Box display="flex" flexDirection="column" gap={3}>
            <PayRateDetail offer={offer} />
            <PlannedDatesDetail offer={offer} />
            <CommitmentDetail offer={offer} />
          </Box>
        </DetailsLayoutContainer>
      </RoundedBox>
    </DetailComponentMetaContext.Provider>
  );
};

type OfferDetailsFacadeProps = {
  offer: TalentOrEmployerOffer;
  job: Job;
};

const OfferDetailsFacade = ({ offer, job }: OfferDetailsFacadeProps) => {
  const showOfferExpires = showOfferExpiresSection(offer?.status);
  return (
    <DetailsLayoutContainer mt={3}>
      <Box display="flex" flexDirection="column" gap={2}>
        <JobTypeDetal job={job} />
        <PayRateDetail offer={offer} />
        <PlannedDatesDetail offer={offer} />
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <CommitmentDetail offer={offer} />
        {showOfferExpires && <OfferExpiresDetail offer={offer} />}
      </Box>
    </DetailsLayoutContainer>
  );
};

type DetailsLayoutContainerProps = ComponentPropsWithoutRef<typeof Box> & {
  children: ReactNode;
};

const DetailsLayoutContainer = (props: DetailsLayoutContainerProps) => {
  return <Box className={styles.layout} {...props} />;
};

type JobTypeDetalProps = {
  job: Job;
};

const JobTypeDetal = ({ job }: JobTypeDetalProps) => {
  const user = useUser();

  const { label } = getJobTypeTagMeta(job.job_type, user?.account_type);

  return <Detail label="Job type" value={label} />;
};

type OfferDetailProps = {
  offer: TalentOrEmployerOffer;
};

const PayRateDetail = ({ offer }: OfferDetailProps) => {
  const amount = offer.payment_amount;
  const paymentType = offer.payment_type;

  const isUpdated =
    offer.updated_fields.includes("payment_amount") ||
    offer.updated_fields.includes("payment_type");

  return (
    <Detail
      updated={isUpdated}
      label="Pay rate"
      value={`${formatCurrency(amount)} ${getEnumLabel(
        JOB_PAYMENT_TYPE_LABELS,
        paymentType,
      )}`}
    />
  );
};

const PlannedDatesDetail = ({ offer }: OfferDetailProps) => {
  const startDate = offer.proposed_start_date as IsoDate;
  const endDate = offer.proposed_end_date as IsoDate;
  const content = (
    <>
      {startDate && (
        <>
          <Date date={startDate} format={DateFormats["Jan 1, 1970"]} />
          {" - "}
        </>
      )}
      {endDate && (
        <>
          Ends <Date date={endDate} format={DateFormats["Jan 1, 1970"]} />
        </>
      )}
    </>
  );

  const isUpdated =
    offer.updated_fields.includes("proposed_start_date") ||
    offer.updated_fields.includes("proposed_end_date");

  return <Detail updated={isUpdated} label="Dates" value={content} />;
};

const CommitmentDetail = ({ offer }: OfferDetailProps) => {
  return (
    <Detail
      updated={offer.updated_fields.includes("anticipated_weekly_hours")}
      label="Commitment"
      value={`${offer.anticipated_weekly_hours} hours per week`}
    />
  );
};

const OfferExpiresDetail = ({ offer }: OfferDetailProps) => {
  const date = offer.time_of_expiry as IsoDate;
  const timeOfExpiry = dateDifferenceFromNow(offer.time_of_expiry, "days");
  const expireString = (
    <>
      {timeOfExpiry > 0 && (
        <>
          In {timeOfExpiry} day{pluralize(timeOfExpiry)} |{" "}
        </>
      )}
      <Date date={date} format={DateFormats["Jan 1, 1970"]} />
    </>
  );

  return <Detail label="Offer expires" value={expireString} />;
};

type DetailProps = {
  label: string;
  value: string | JSX.Element;
  updated?: boolean;
};

const Detail = ({ label, value, updated }: DetailProps) => {
  const { showUpdatedLabel, detailTypographySize } = useDetailMetaContext();

  const _label =
    showUpdatedLabel && updated ? (
      <>
        {label} - <span className="positive-2">Updated</span>
      </>
    ) : (
      label
    );

  return (
    <Box>
      <Typography variant="label" size="small" component="h3" color="grey-1">
        {_label}
      </Typography>
      <Typography size={detailTypographySize || "large"} component="p">
        {value}
      </Typography>
    </Box>
  );
};
